























































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import CartItem from "@/components/checkout/CartItem.vue";
import { Business, CartService, Client, Order, Role, User } from "@/types";
import OrderStoreModule from "@/store/modules/order";
import PaymentStoreModule from "@/store/modules/payment";
import GatewayModule from "@/store/modules/paymentVendorGateway";
import MembershipModule from "@/store/modules/clientMembership";
import paystackStoreModule from "@/store/modules/paystack";
import ClientModule from "@/store/modules/client";
import { PHONE_REGEX_KE } from "@/util/constants";
import locationStoreModule from "@/store/modules/location";
import voucherStoreModule from "@/store/modules/voucher";
import { validatePhone } from "@/util/payment";

const stripePubKey = process.env.VUE_APP_STRIPE_PUB_KEY;

const { mapActions: orderActions } = createNamespacedHelpers("ORDER_CHECKOUT");
const { mapActions: paymentActions } =
  createNamespacedHelpers("ORDER_PAYMENTS");

const { mapActions: VendorGatewaysAction, mapGetters: VendorGatewayGetters } =
  createNamespacedHelpers("GATEWAY");
const { mapActions: membershipActions, mapGetters: membershipGetters } =
  createNamespacedHelpers("_Membership");
const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("_Client");

const { mapActions: paystackActions } = createNamespacedHelpers("PAYSTACK_");

const { mapActions: voucherActions } = createNamespacedHelpers("CHECK_VOUCHER");

const payment_items = [
  { title: "Pay By Cash", value: "cash" },
  { title: "Pay By Mpesa", value: "mpesa" },
];

export default Vue.extend<any, any, any, any>({
  name: "DepositCheckoutPage",
  components: { CartItem },
  data: () => ({
    isLoading: false,
    loadingMessage: "Processing Payment...",
    addToCart: false,
    cashDialog: false,
    cardDialog: false,
    mpesaDialog: false,
    shippingDialog: false,
    addressDialog: false,
    gift_code: "",
    paymentItems: payment_items,
    stripePk: stripePubKey,
    qr: {
      step: 1,
      timer: 300,
      creatingOrder: false,
      fetchingOrder: false,
      interval: null,
    },
    cash: {
      cash_amount: 0,
      cash_balance: 0,
      total_amount: 0,
    },
    card: {
      total_amount: 0,
      card_number: undefined,
      exp_date: "",
      cvc: undefined,
      client_name: "",
      client_email: "",
      client_phone: "",
      subscription: false,
    },
    mpesa: {
      total_amount: 0,
      phone: "",
    },
    orderID: "",
    qrcode: "",
    //clientSubscriptions: [],
    selectedSubscription: null,
    subscriptionDiscount: 0,
    shippingMethods: [],
    selectedShippingMethod: undefined,
    shippingCost: 0,
    selectedAddress: undefined,
    addressChoice: undefined,
    selectedQRPaymentMethod: undefined,
    mpesaPhoneRules: [
      (v: string) => !!v || "Phone Number is required",
      (v: string) => PHONE_REGEX_KE.test(v) || "Invalid phone number",
    ],
    order: undefined as undefined | Order,
    pollInterval: undefined as any,
    pollTimeout: undefined as any,
    paystackDialog: false,
    authUrl: "",
    payOnlyDeposit: false,
    voucherAmount: 0,
  }),
  computed: {
    ...VendorGatewayGetters(["gateways"]),
    ...clientGetters(["getClient"]),
    ...membershipGetters(["clientMembershipPage"]),
    isStripeSetup(): boolean {
      // return this.gateways.isStripeSetup || false;
      return true;
    },
    services: function (): CartService[] {
      return this.$store.getters["cart/services"];
    },
    client: function (): Client {
      return this.$store.getters["cart/client"];
    },
    discountAmount() {
      return this.$store.getters["cart/getDiscount"];
    },
    appliedDiscounts() {
      return this.$store.getters["cart/discounts"];
    },
    cartQty: function (): number {
      return this.$store.getters["cart/quantity"];
    },
    cartTotal: function (): number {
      return this.$store.getters["cart/total"];
    },
    grandTotal: function (): number {
      return this.cartTotal - this.subscriptionDiscount + this.shippingCost;
    },
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    employee(): User {
      return this.role.user as User;
    },
    user(): User {
      return this.$store.getters.user;
    },
    deposit(): number {
      return this.$store.getters["cart/deposit"];
    },
    cartTotalWithDeposit(): number {
      return this.$store.getters["cart/totalWithDeposit"];
    },
  },
  watch: {
    cash: {
      handler() {
        const { cash_amount, cash_balance } = this.cash;
        const total = this.deposit;
        this.cash.cash_balance = total > cash_amount ? 0 : cash_amount - total;
        this.cash.total_amount = cash_amount - cash_balance;
      },
      deep: true,
      immediate: true,
    },
    payOnlyDeposit: {
      handler() {
        const { cash_amount, cash_balance } = this.cash;
        const total =
          this.deposit - this.discountAmount > 0
            ? this.deposit - this.discountAmount
            : this.cartTotal;
        this.cash.cash_balance = total > cash_amount ? 0 : cash_amount - total;
        this.cash.total_amount = cash_amount - cash_balance;
      },
      immediate: true,
    },
    role() {
      if (this.role) {
        this.getVendorGateways();
        const bid = (this.role.business as Business)._id;
        this.$store.dispatch("cart/fetchDiscounts", bid);
      }
    },
  },
  created() {
    if (this.role) {
      const bid = (this.role.business as Business)._id;
      this.$store.dispatch("cart/fetchDiscounts", bid);
    }
  },
  methods: {
    ...orderActions(["createOrder", "fetchOrderList", "retryOrderPayment"]),
    ...paymentActions(["createPayment"]),
    ...VendorGatewaysAction(["createTransaction", "fetchPaymentGateways"]),
    ...membershipActions([
      "fetchClientSubscriptions",
      "createMembershipSession",
      "verifyMembershipApply",
      "fetchClientMembershipList",
    ]),
    ...clientActions(["updateUserAddress", "fetchClient"]),
    ...paystackActions(["initTransaction"]),
    ...voucherActions(["validateVoucher"]),
    getVendorGateways() {
      if (this.role) {
        const vendor_id = (this.role.business as Business)._id;
        this.fetchPaymentGateways(vendor_id);
        console.log("Fetch");
      }
    },
    openDialog(type: string) {
      if (type == "cash") {
        this.cashDialog = true;
        this.cash = {
          cash_amount: 0,
          cash_balance: 0,
          total_amount: 0,
        };
      }

      if (type == "card") {
        if (this.client) {
          this.card.client_name = this.client.fullName;
          this.card.client_email = this.client.email;
          this.card.client_phone = this.client.phone;
        }
        this.card.total_amount = this.grandTotal - this.discountAmount;
        this.cardDialog = true;
      }

      if (type == "mpesa") {
        this.mpesa.total_amount = this.grandTotal - this.discountAmount;
        this.mpesaDialog = true;
      }
      if (type == "qr") {
        if (!this.client) {
          this.$swal.fire({
            icon: "error",
            title: "Payment Not Initialized",
            text: "Please provide client Details",
          });
          return;
        }

        if (this.services.length == 0) {
          this.$swal.fire({
            icon: "error",
            title: "Cart error",
            text: "Please add items to cart",
          });
          return;
        }
      }
    },
    formatPhone(phone: string) {
      const codedPlus =
        /^(?:\+254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const zero = /^(?:0)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const coded =
        /^(?:254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;

      if (phone.match(codedPlus)) {
        const newPhoneNumber = phone.substring(1);
        return newPhoneNumber;
      }

      if (phone.match(coded)) {
        return phone;
      }

      if (phone.match(zero)) {
        const newPhoneNumber = phone.substring(1);
        const newPhone = `254${newPhoneNumber}`;
        return newPhone;
      }
      return phone;
    },
    async doMpesaPayment() {
      const valid = (
        this.$refs.mpesaForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }

      const { phone } = this.mpesa;

      if (!validatePhone(phone)) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide a valid phone Number!",
        });
        return;
      }

      const orderDetails = {
        clientId: this.client._id,
        notes: "Order Notes",
        cost: this.grandTotal,
        paymentMethod: "m-pesa",
        services: this.services.map((service) => {
          return {
            name: service.name,
            service: service.id,
            staff: service.staff,
            startDate: new Date(
              `${service.appointmentDate} ${service.appointmentTime}`
            ).toISOString(),
            quantity: service.quantity,
            unitPrice: service.unitPrice,
            total: service.sub_total,
          };
        }),
        payment: {
          method: "m-pesa", //card|m-pesa|cash|paylater
          source: phone,
          amount: this.deposit,
          currency: "KES",
        },
      };
      this.isLoading = true;
      this.loadingMessage = "Creating Order...";
      this.saveOrder({ ...orderDetails })
        .then((order: any) => {
          if (order) {
            this.processOrderPayment((order as Order)._id);
          }
        })
        .catch((e) => {
          this.loadingMessage = "Order Not Created";
          this.$swal.fire({
            icon: "error",
            title: "Order Not Created!",
            text: (e as Error).message || "Error while processing Order",
          });
          this.isLoading = false;
        });
    },

    async retryPayment() {
      if (this.order) {
        const orderId = (this.order as Order)._id;
        const cost = this.order.cost;
        const { method, currency, amount, source } = this.order.payment;
        try {
          this.order = undefined;
          await this.retryOrderPayment({
            id: orderId,
            payment: {
              method: method,
              currency: currency || "KES",
              amount: cost || amount,
              source: source || "",
            },
          });
          this.processOrderPayment(orderId);
        } catch (e) {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Order Payment Failed!",
            text: (e as Error).message || "Error while processing Order",
          });
        }
      }
    },
    async doCashPayment() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }

      const orderDetails = {
        clientId: this.client._id,
        notes: "Order Notes",
        cost: this.grandTotal,
        paymentMethod: "cash",
        services: this.services.map((service) => {
          return {
            name: service.name,
            service: service.id,
            staff: service.staff,
            startDate: new Date(
              `${service.appointmentDate} ${service.appointmentTime}`
            ).toISOString(),
            quantity: service.quantity,
            unitPrice: service.unitPrice,
            total: service.sub_total,
          };
        }),
        payment: {
          method: "cash", //card|m-pesa|cash|paylater
          source: this.client.phone,
          amount: this.deposit,
          currency: "KES",
        },
      };
      this.isLoading = true;
      this.loadingMessage = "Processing order.....";
      this.saveOrder({ ...orderDetails })
        .then((order) => {
          if (order) {
            const _order = order as Order;
            this.processOrderPayment(_order._id);
          }
        })
        .catch((e) => {
          this.$swal.fire({
            icon: "error",
            title: "Cash Payment Error",
            text: (e as Error).message || "An Error Occurred!",
          });
        });
    },
    processOrderPayment(orderId: string) {
      this.loadingMessage = "Processing Payment...";
      this.pollInterval = setInterval(async () => {
        const updatedOrder: any = await this.fetchOrder(orderId);
        if (
          updatedOrder &&
          updatedOrder.payment.amount - updatedOrder.discountAmount >=
            this.deposit
        ) {
          this.$swal.fire({
            icon: "success",
            title: "Order Paid",
            text: "Order Created and Deposit Paid successful ",
          });
          this.isLoading = false;
          this.cashDialog = false;
          this.cardDialog = false;
          this.mpesaDialog = false;
          this.resetForms();
          this.$router.push(`/order/${orderId}`);
          clearInterval(this.pollInterval);
        }
      }, 5000);

      this.pollTimeout = setTimeout(async () => {
        console.log("--Timeout--");
        clearInterval(this.pollInterval);
        const updatedOrder = await this.fetchOrder(orderId);
        this.order = updatedOrder as Order;
      }, 60000);
    },
    saveOrder(data: any) {
      return new Promise((resolve, reject) => {
        if (this.role) {
          const businessId = (this.role.business as Business)._id;
          this.createOrder({
            ...data,
            businessId,
            appliedDiscounts: this.appliedDiscounts.map((item) => item._id),
            depositAmount: this.deposit,
          })
            .then((order) => {
              resolve(order);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error("Order Not Initialized"));
        }
      });
    },

    fetchOrder(orderId: any) {
      return new Promise((resolve, reject) => {
        if (this.role) {
          const businessId = (this.role.business as Business)._id;
          this.fetchOrderList(`?orderId=${orderId}&businessId=${businessId}`)
            .then((response) => {
              const { order } = response.data;
              resolve(order);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error("Order Not Initialized"));
        }
      });
    },

    savePayment(data: any) {
      return this.createPayment(data);
    },
    resetForms() {
      this.mpesa.phone = "";
      this.cash = {
        cash_amount: 0,
        cash_balance: 0,
        total_amount: 0,
      };
      this.card = {
        total_amount: 0,
        card_number: undefined,
        cvc: undefined,
        exp_date: "",
        client_name: "",
        client_email: "",
        client_phone: "",
        subscription: false,
      };
      this.gift_code = "";
      this.selectedShippingMethod = undefined;
      this.$store.dispatch("cart/deleteCart");
    },

    resetQrPayment() {
      this.qr.step = 1;
      this.qr.creatingOrder = false;
      this.qr.fetchingOrder = false;
      this.qrcode = "";
      this.orderID = "";
      this.qr.timer = 300;

      clearInterval(this.qr.interval as any);
    },

    qrTimer() {
      if (this.qr.timer > 0) {
        setTimeout(() => {
          this.qr.timer -= 1;
          this.qrTimer();
        }, 1000);
      }
    },
    payByPaystack() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }
      if (!this.role.business.vendorPlan) {
        this.$swal.fire({
          icon: "error",
          title: "Ahidi Plan not found",
          text: "An Ahidi Plan is required!",
        });
        return;
      }

      // const total_amount = this.grandTotal - this.discountAmount;
      const orderDetails = {
        clientId: this.client._id,
        notes: "Order Notes",
        cost: this.grandTotal,
        paymentMethod: "card",
        services: this.services.map((service) => {
          return {
            name: service.name,
            service: service.id,
            staff: service.staff,
            startDate: new Date(
              `${service.appointmentDate} ${service.appointmentTime}`
            ).toISOString(),
            quantity: service.quantity,
            unitPrice: service.unitPrice,
            total: service.sub_total,
          };
        }),
        payment: {
          method: "card", //card|m-pesa|cash|paylater
          source: "paystack",
          amount: 0, //this.deposit,
          currency: "KES",
        },
      };
      this.isLoading = true;
      this.loadingMessage = "Processing order.....";
      this.saveOrder({ ...orderDetails })
        .then(async (order: any) => {
          if (order) {
            const url = await this.initTransaction({
              amount: this.deposit * 100,
              reference: order._id,
              email: order.client.email,
              subaccount: order.business.paystackSubaccountCode,
            });

            this.authUrl = url;
            this.paystackDialog = true;
            //this.isLoading = false;
            this.processOrderPayment(order._id);
          }
        })
        .catch((e) => {
          this.$swal.fire({
            icon: "error",
            title: "Card Payment Error",
            text: (e as Error).message || "An Error Occurred!",
          });
        });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_CHECKOUT")) {
      this.$store.registerModule("ORDER_CHECKOUT", OrderStoreModule);
    }

    if (!this.$store.hasModule("ORDER_PAYMENTS")) {
      this.$store.registerModule("ORDER_PAYMENTS", PaymentStoreModule);
    }

    if (!this.$store.hasModule("GATEWAY")) {
      this.$store.registerModule("GATEWAY", GatewayModule);
    }
    if (!this.$store.hasModule("_Membership")) {
      this.$store.registerModule("_Membership", MembershipModule);
    }

    if (!this.$store.hasModule("_Client")) {
      this.$store.registerModule("_Client", ClientModule);
    }

    if (!this.$store.hasModule("PAYSTACK_")) {
      this.$store.registerModule("PAYSTACK_", paystackStoreModule);
    }
    if (!this.$store.hasModule("LOCATION_LIST")) {
      this.$store.registerModule("LOCATION_LIST", locationStoreModule);
    }

    if (!this.$store.hasModule("CHECK_VOUCHER")) {
      this.$store.registerModule("CHECK_VOUCHER", voucherStoreModule);
    }
  },
  beforeDestroy() {
    clearTimeout(this.pollTimeout);
    clearInterval(this.pollInterval);
    this.$store.unregisterModule("ORDER_CHECKOUT");
    this.$store.unregisterModule("ORDER_PAYMENTS");
    this.$store.unregisterModule("_Membership");
    this.$store.unregisterModule("_Client");
    this.$store.unregisterModule("PAYSTACK_");
    this.$store.unregisterModule("LOCATION_LIST");
    this.$store.unregisterModule("CHECK_VOUCHER");
  },
});
